import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(
    // @Inject('BASE_API_URL') private baseUrl: string
    private gs: GlobalService
  ) { }

  substituiGEPJson(t: string) {
    var repli = t.substring(t.toLowerCase().indexOf('/select')).substring(1);

    var retorno = repli.replace(/%20/gi, ' ').replace(/\[AS\]/gi, "'").replace(/\//gi, '');

    retorno = retorno.replace(/\[di\]/gi, "/").replace(/\[ma\]/gi, "+");

    retorno = retorno.replace('[@],[@]FilterCaption[@]:[@]Select[@][C1],[C][@]FilterName[@]:[@]DataFinal[@],[@]FilterValue[@]:[@]20200101[@],[@]FilterCaption[@]:[@]DataInicial[@][C1],[C][@]FilterName[@]:[@]DataInicial[@],[@]FilterValue[@]:[@]20200101[@],[@]FilterCaption[@]:[@]Datafinal[@][C1]]', '');

    return retorno;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const apiReq = req.clone({ url: `${this.baseUrl}${req.url}` });
    // return next.handle(apiReq);
    // console.log(req);

    const { method, url } = req;

    //console.log(method, url);

    //alert(req.method);

    if (url.toLowerCase().indexOf('gepjson') > -1 && url.toLowerCase().indexOf('/select') > -1) {
      let _body = { query: this.substituiGEPJson(url) };
      //console.log(_body, this.gs.dadosUser.UserID.toString());
      req = req.clone({
        method:'post',
        url: 'https://apinodejs.gepbusiness.com/web/queryFB',
        setHeaders: {
          'Content-Type': 'application/json',
          'x-access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc5NDMzNzI1fQ.5p3DTHVHxH1uoRNw6DbXRTgVReN5maRtXlip3w9PZH8',
          'e-usuario': this.gs.dadosUser.UserID.toString(),
        },
        body: _body
      });
    } else {
      //req = req.clone();
    }
    return next.handle(req);
  }
}
